import axios from 'axios';

function _getCsrfToken(){
    let re = /csrftoken=(\w+)/;
    let cookieString = document.cookie;
    let m;
    let csrf;

    if ((m = re.exec(cookieString)) !== null) {
        if (m.index === re.lastIndex) {
            re.lastIndex++;
        }
        csrf = m[1];
    }
    return csrf;
}

const instance = axios.create({
    mode: 'cors',
    baseURL: process.env.API_URL || '/planner/api/v1/',
});

instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common['X-CSRFToken'] = _getCsrfToken();

// Add a request interceptor
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});

export default instance;