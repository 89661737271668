import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactModal from 'react-modal';
import actions from "../../store/actions";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import Reaptcha from 'reaptcha';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './day-planner.scss';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment/locale/sk';
import validator from 'validator';

const moment = extendMoment(Moment);
moment.locale('sk');

const mapDispatchToProps = dispatch => ({
    getOrders: (slot, date) => dispatch(actions.getOrders(slot, date)),
    createOrder: (order) => dispatch(actions.createOrder(order)),
});

const mapStateToProps = state => ({
    ...state
});

class DayPlanner extends Component {
    state = {
        creating: false,
        createData: {start: null, end: null},
        verified: false,
        phoneValid: true
    };

    componentDidMount() {
        if(this.props.calendar.user.id) {
            this.setState({
                verified: true
            });
        }
    }

    componentDidUpdate(prevProps) {
    }

    createNewEvent(data) {
        let data_hours = String(data.start.getHours());
        let data_minutes = String(data.start.getMinutes());
        if (data_minutes === "0"){
            data_minutes = "00"
        }
        let end_hours = parseInt(this.props.calendar.settings.planner_end_time.slice(0, 2)) + 1;
        let diffMs = (data.end - data.start);
        let minutes = Math.floor((diffMs/1000)/60);

        for(let order of this.props.calendar.orders[this.props.slot]) {
            let range1 = moment.range( moment(data.start),  moment(data.end));
            let range2 = moment.range( moment(order.start_date + order.start_time, 'YYYY-MM-DDHH:mm'),  moment(order.end_date + order.end_time, 'YYYY-MM-DDHH:mm'));
            if(range1.overlaps(range2)){
              ToastsStore.error("Ľutujeme, v danom čase nie je možné sa objednať");
              return;
            }
            let data_time = data_hours.concat(':', data_minutes, ':00');
            let planner_end_time = String(end_hours).concat(':00:00');
            if(data_time === planner_end_time){
               ToastsStore.error("Ľutujeme, v danom čase nie je možné sa objednať");
               return;
            }
        }

        let minTime = moment().add(this.props.calendar.settings.planner_order_create_hours, 'hours');
        if(minTime > moment(data.start)){
            console.log(minTime);
            console.log(moment(data.start));
            ToastsStore.error("Objednávka môže byt vytvorená " + this.props.calendar.settings.planner_order_create_hours + "h. od aktuálneho času");
            return;
        }

        if (minutes === 60) {
            this.setState({createData: data});
            this.openCreatingModal();
        }else{
            ToastsStore.error("Kliknite a potiahnite nadol");
        }

    };

    saveNewEvent(e) {
        e.preventDefault();
        let order = {
            slot: this.props.slot,
            start_time: this.state.createData.start.toTimeString().split(' ')[0],
            start_date: this.state.createData.start.toISOString().split('T')[0],
            end_time: this.state.createData.end.toTimeString().split(' ')[0],
            end_date: this.state.createData.end.toISOString().split('T')[0],
            user: this.props.calendar.user.id,
            email: e.target.elements.email.value,
            phone: e.target.elements.phone.value,
            first_name: e.target.elements.first_name.value,
            last_name: e.target.elements.last_name.value,
            spz:  e.target.elements.spz.value,
            car_model:  e.target.elements.car_model.value,
        };
        this.props.createOrder(order).then(response => {
            this.closeCreatingModal();
        });
    };

    phoneVerify(){
        this.setState({phoneValid: validator.isMobilePhone(this.phoneNumber.value, ["sk-SK"])});
    }

    openCreatingModal() {
        this.setState({ creating: true });
    };

    closeCreatingModal() {
        this.setState({ creating: false });
        this.setState({ createData: {start: null, end: null}});
    };

    onVerify(recaptchaResponse){
        this.setState({
          verified: true
        });
      };

    render() {
        let ord=[];
        let orders = this.props.calendar.orders[this.props.slot];
        if (orders) {
            ord = orders.map((event => {
                return {
                    title: 'Obsadené',
                    start: moment(event.start_date + event.start_time, 'YYYY-MM-DDHH:mm').toDate(),
                    end: moment(event.end_date + event.end_time, 'YYYY-MM-DDHH:mm').toDate()
                }
            }));
        }

        const localizer = momentLocalizer(moment);

        const getTime = (time) => {
          if(time.getMinutes() < 10){
            return time.getHours() + ':0' + time.getMinutes();
          }else{
            return time.getHours() + ':' + time.getMinutes();
          }
        };


        return (
            <div className='day-planner col-md-4 my-4'>
                <div>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} lightBackground />
                </div>
                <h3 className='slot-name'>{this.props.slotName}</h3>
                <Calendar
                  selectable
                  culture = 'sk'
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  min={moment(this.props.calendar.settings.planner_start_time, 'HH:mm:ss').toDate()}
                  max={moment(this.props.calendar.settings.planner_end_time, 'HH:mm:ss').add(1, 'hours').toDate()}
                  defaultView={'day'}
                  views={['day']}
                  toolbar={false}
                  onSelectEvent={event => alert(event.title)}
                  onSelectSlot={this.createNewEvent.bind(this)}
                  formats={{
                      eventTimeRangeFormat: ({ start, end }, culture, local) => getTime(start) + ' — ' + getTime(end),
                      timeGutterFormat: 'HH:mm'
                  }}
                  step={30}
                  onSelecting={({start, end}) =>
                  {
                      let diffMs = (end - start);
                      var minutes = Math.floor((diffMs/1000)/60);
                      return minutes <= 60;
                  }}
                  timeslots={2}
                  date={this.props.calendar.startDate}
                  events={ord}
                  onNavigate={()=>{}}
                />
                <ReactModal
                   isOpen={this.state.creating}
                   ariaHideApp={false}
                   contentLabel="Minimal Modal Example"
                   style={{
                       content: {
                           width: '40%',
                           height:'450px',
                           top: '30%',
                           left: '30%',
                           bottom: 'auto',
                       }
                   }}
                >
                {moment(this.state.createData.start).format("DD.MM.YYYY HH:mm")} - { moment(this.state.createData.end).format("DD.MM.YYYY HH:mm")}

                    <form onSubmit={this.saveNewEvent.bind(this)}>
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Meno:</label>
                                    <input type="text" name="first_name" className="form-control" required defaultValue={this.props.calendar.user ? this.props.calendar.user.first_name : ''}  placeholder="Meno"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Priezvisko:</label>
                                    <input type="text" name="last_name" className="form-control" required defaultValue={this.props.calendar.user ? this.props.calendar.user.last_name : ''}  placeholder="Priezvisko"/>
                                </div>
                            </div>
                        </div>                      
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>E-mail:</label>
                                    <input type="email" name="email" className="form-control" defaultValue={this.props.calendar.user ? this.props.calendar.user.email : ''}  placeholder="E-mail"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Model auta:</label>
                                    <input type="text" name="car_model" className="form-control" placeholder="napr. Ford Kuga"/>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>ŠPZ auta:</label>
                                    <input type="text" name="spz" maxLength="10" minLength="4" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Číslo mobilného telefónu:</label>
                                    <input type="text" onChange={(e) => {this.phoneVerify()}} ref={(e) => {this.phoneNumber = e}} name="phone" className="form-control" required defaultValue={this.props.calendar.user ? this.props.calendar.user.phone : ''} placeholder="+421XXXXXXXXX" />
                                     {this.state.phoneValid === false ? <div className="text-danger" >Zadávajte v tvare +421XXXXXXXXX</div> : " "}
                                </div>
                            </div>
                            <div className="col-md-12 text-left my-3">
                            <div className="form-group form-check">
    <input type="checkbox" className="form-check-input" id="exampleCheck1" required />
    <label className="form-check-label" htmlFor="exampleCheck1">Súhlasím so <a href="https://www.pneuonline.sk/sk/dokumenty/ochrana-osobnych-udajov/"> spracovaním osobných údajov </a>. </label>
  </div>
                            </div>
                        </div>
                        {this.props.calendar.user.id ? null:
                            <div className="row">
                                <div className="col-md-6">
                                    <Reaptcha sitekey="6LcIRrgUAAAAAHkDbGl_1F1Y-4KgfcsjvFM50y4q"
                                              onVerify={this.onVerify.bind(this)}/>
                                </div>
                            </div>
                        }
                       <div className="clearfix pt-4">
                           <div className="float-right">
                            <button className="btn btn-secondary mr-2" onClick={this.closeCreatingModal.bind(this)}>Zrušiť</button>
                            <button type="submit" value="Submit" className="btn btn-primary ml-2" disabled={!this.state.verified || !this.state.phoneValid}>Potvrdiť</button>
                           </div>
                       </div>
                       
                    </form>
                   
                </ReactModal>
            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DayPlanner);
