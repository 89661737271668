import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

const state = {
    calendar: {
        settings: {},
        user: {},
        orders: {},
        offices: [],
        startDate: new Date(),
    },
};

export default function configureStore(initialState = state) {
    return createStore(
        rootReducer,
        initialState,
        applyMiddleware(thunk)
    );
}