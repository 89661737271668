import {ACTION_TYPES} from '../actions';

export default (state = {}, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_SETTINGS:
            return Object.assign({}, state, {
                settings: action.payload
            });
        case ACTION_TYPES.GET_SLOT:
            return Object.assign({}, state, {
                slots: action.payload.results
            });
        case ACTION_TYPES.GET_BRANCH_OFFICES:
            return Object.assign({}, state, {
                offices: action.payload.results
            });
        case ACTION_TYPES.GET_ORDERS:
            return Object.assign({}, state, {
                orders:Object.assign({}, state.orders, {[action.slot]: action.payload.results}),
            });
        case ACTION_TYPES.SELECT_START_DATE:
            return Object.assign({}, state, {
                startDate: action.date
            });
        case ACTION_TYPES.GET_USER:
            return Object.assign({}, state, {
                user: action.payload
            });
        case ACTION_TYPES.CREATE_ORDER:
            const newResults = state.orders[action.payload.slot].concat([action.payload]);
            return Object.assign({}, state, {
                orders:Object.assign({}, state.orders, {[action.payload.slot]: newResults}),
            });
        default:
            return state
    }
}
