import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "../../components/loader";
import DayPlanner from "../../components/dayPlanner";
import actions from "../../store/actions";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import sk from "date-fns/locale/sk";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

registerLocale("sk", sk);

const mapDispatchToProps = dispatch => ({
  getBranchOffices: () => dispatch(actions.getBranchOffices()),
  getSlot: branch => dispatch(actions.getSlot(branch)),
  selectStartDay: date => dispatch(actions.selectStartDay(date)),
  getOrders: (slot, date) => dispatch(actions.getOrders(slot, date)),
  getUser: () => dispatch(actions.getUser()),
  getSettings: () => dispatch(actions.getSettings())
});
const mapStateToProps = state => ({
  ...state
});

class Planner extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    actual_branch: ""
  };

  componentDidMount() {
    const branches = this.props.getBranchOffices();
    const settings = this.props.getSettings();
    Promise.all([branches, settings]).then(() => {
      this.updateSlots(this.props.calendar.offices[0].id);
    });
    this.props.getUser();
  }

  handleChange(date) {
    this.updateOrders(date);
  }

  updateSlots(branch) {
    this.setState({ actual_branch: branch });
    const slot = this.props.getSlot(branch);
    Promise.all([slot]).then(() => {
      this.updateOrders(this.selectedDate());
    });
  }

  handleClick(value) {
    let date = new Date(this.selectedDate());
    if (value === "prev") {
      if (date.getDay() === 1) {
        date.setDate(date.getDate() + -3);
      } else {
        date.setDate(date.getDate() + -1);
      }
    } else if (value === "next") {
      if (date.getDay() === 5) {
        date.setDate(date.getDate() + 3);
      } else {
        date.setDate(date.getDate() + 1);
      }
    }
    this.updateOrders(date);
  }

  addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  updateOrders(date) {
    this.props.selectStartDay(date);

    for (let slot of this.props.calendar.slots) {
      this.props.getOrders(slot.id, moment(date).format("DD.MM.YYYY"));
    }
  }

  selectedDate() {
    let selectedDate = new Date(this.props.calendar.startDate);
    if (selectedDate.getDay() === 6) {
      selectedDate.setDate(selectedDate.getDate() + 2);
    }
    if (selectedDate.getDay() === 0) {
      selectedDate.setDate(selectedDate.getDate() + 1);
    }
    return selectedDate;
  }

  render() {
    let today = new Date();
    if (today.getDay() === 0) {
      today.setDate(today.getDate() + 1)     
    } else if (today.getDay() === 6) {
      today.setDate(today.getDate() + 2)
    }
    const slots = this.props.calendar.slots ? (
      this.props.calendar.slots.map(slot => (
       <DayPlanner key={slot.id} slot={slot.id} slotName={slot.name} />
      ))
    ) : (
      <Loader />
    );

    const options = this.props.calendar.offices.map(office => (
      <option key={office.id} value={office.id}>
        {office.name}
      </option>
    ));
    const isWeekday = date => {
      const day = date.getDay();
      return day !== 0 && day !== 6;
    };

    
    const date = (
      <DatePicker
        // selected={this.props.calendar.startDate}
        selected={this.selectedDate()}
        onChange={this.handleChange}
        minDate={today}
        maxDate={this.addDays(
          today,
          this.props.calendar.settings.planner_order_create_days
        )}
        locale="sk"
        dateFormat="dd.MM.yyyy"
        filterDate={isWeekday}
      />
    );

    return (
      <div className="planner container my-4">
        <h1>Objednanie do pneuservisu</h1>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="alert alert-success" role="alert">
              <p className="alert-planner">
             <strong>Kliknutím a potiahnutím nadol výtvoríte objednávku.</strong> 
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 my-4">
            <h4 className="text-left"> Vyberte si prevádzku:</h4>
            <select
              className="custom-select custom-select-lg planner-select"
              value={this.state.actual_branch}
              onChange={event => {
                this.updateSlots(event.target.value);
              }}
            >
              {options}
            </select>

            
          </div>
          <div className="col-md-4 my-4">
              <h4 className="text-left"> Vyberte si dátum:</h4>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <button
                    className="btn btn-primary btn-date-primary"
                    type="button"
                    onClick={() => {
                      this.handleClick("prev");
                    }}
                    disabled={this.props.calendar.startDate <= today}
                  >
                    {" "}
                    &lt;{" "}
                  </button>
                </div>
                {date}
                <div className="input-group-append">
                  <button
                    type="button"
                    onClick={() => {
                      this.handleClick("next");
                    }}
                    className="btn btn-primary btn-date-primary"
                    disabled={
                      this.props.calendar.startDate >=
                      this.addDays(
                        today,
                        this.props.calendar.settings.planner_order_create_days -
                          1
                      )
                    }
                  >
                    {" "}
                    &gt;{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2 my-4">
              <h4 className="text-left text-white">.</h4>
              <div className="input-group mb-3">
                <div className="input-group">
                  <a role="button" href="https://www.pneuonline.sk/sk/dokumenty/cennik/" className="btn btn-primary button-pricing"> Cenník </a>
                </div>
              </div>
            </div>
        </div>
        
        <div className="row mt-4">
          <div className="col-md-12 mb-4">
            <div className="row"> {slots}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Planner);
