import {CALENDAR_ACTION_TYPES, getSlot, getBranchOffices, getOrders, selectStartDay, getUser, createOrder, getSettings} from './calendar';

export default {
    getSlot,
    getBranchOffices,
    getOrders,
    selectStartDay,
    getUser,
    createOrder,
    getSettings
};

export const ACTION_TYPES = {
    ...CALENDAR_ACTION_TYPES
};