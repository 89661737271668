import api from '../api';
import $ from 'jquery';

export const CALENDAR_ACTION_TYPES = {
    GET_BRANCH_OFFICES: 'GET_BRANCH_OFFICES',
    GET_SLOT: 'GET_SLOT',
    GET_ORDERS: 'GET_ORDERS',
    SELECT_START_DATE:'SELECT_START_DATE',
    GET_USER: 'GET_USER',
    CREATE_ORDER: 'CREATE_ORDER',
    GET_SETTINGS: 'GET_SETTINGS'
};

export const getSlot = (branch) => dispatch => {

    return api.get('slot/', {params: {branch_office: branch}}).then((response) => {
        return dispatch({
            type: CALENDAR_ACTION_TYPES.GET_SLOT,
            payload: response ? response.data : []
        })
    });
};

export const getBranchOffices = () => dispatch => {
    return api.get('brancheoffice/').then((response) => {
        return dispatch({
            type: CALENDAR_ACTION_TYPES.GET_BRANCH_OFFICES,
            payload: response ? response.data : []
        })
    });
};

export const getOrders = (slot, date) => dispatch => {
    return api.get('order/',{
        headers: { 'X-CSRFToken': $('#token').text()},
        params: {slot: slot, start_date:date,is_cancelled:false}
    }).then((response) => {
        return dispatch({
            type: CALENDAR_ACTION_TYPES.GET_ORDERS,
            payload: response ? response.data : [],
            slot : slot
        })
    });
};

export const selectStartDay= (date) => dispatch =>{
    return dispatch({
        type: CALENDAR_ACTION_TYPES.SELECT_START_DATE,
        date : date
    })
};

export const getUser = () => dispatch => {
    return api.get('user/',{}).then((response) => {
        return dispatch({
            type: CALENDAR_ACTION_TYPES.GET_USER,
            payload: response ? response.data : []
        })
    });
};

export const createOrder = (order) => dispatch => {
    return api.post('order/', order).then((response) => {
        return dispatch({
            type: CALENDAR_ACTION_TYPES.CREATE_ORDER,
            payload: response ? response.data : []
        })
    });
};

export const getSettings = () => dispatch => {
    return api.get('settings/').then((response) => {
        return dispatch({
            type: CALENDAR_ACTION_TYPES.GET_SETTINGS,
            payload: response ? response.data : []
        })
    });
};